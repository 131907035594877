import { FC } from 'react'
import Link from 'next/link'
import React from 'react'
import styled from 'styled-components'

const buttonTypes = {
  primary: {
    background: '#7CB227',
    color: 'white',
  },
  default: {
    background: 'grey',
    color: 'white',
  },
  transparent: {
    background: 'transparent',
    color: '#7CB227',
  },
}

const CustomButton = styled.button<{ buttonType: string; uppercase?: boolean; margin: string }>`
  height: 40px;
  border-radius: 15px;
  width: 100%;
  box-shadow: none;
  outline: none;
  border: none;
  text-transform: ${(props) => (props.uppercase ? 'uppercase' : '')};
  ${(props) => buttonTypes[props.buttonType]};
  margin: ${(props) => props.margin};
  cursor: pointer;
`

type CommonButtonType = {
  buttonType?: string
  uppercase?: boolean
  margin?: string
  href?: string
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  [x: string]: any
}

const CommonButton: FC<CommonButtonType> = ({
  children,
  uppercase = true,
  margin = '0',
  buttonType = 'default',
  href,
  ...rest
}) => {
  if (href) {
    return (
      <Link prefetch={false} href={href}>
        <CustomButton uppercase={uppercase} buttonType={buttonType} margin={margin} {...rest}>
          {children}
        </CustomButton>
      </Link>
    )
  } else
    return (
      <CustomButton uppercase={uppercase} buttonType={buttonType} margin={margin} {...rest}>
        {children}
      </CustomButton>
    )
}

export default CommonButton
