import { useDispatch, useSelector } from 'react-redux'

import Button from 'components/Buttons/common'
import ByBritSVG from 'components/svg/by-brit.svg'
import Input from 'components/inputs/input'
import LanguageSwitcher from 'components/LanguageSwitcher'
import LogoSVG from 'components/svg/logo-big.svg'
import MainLayout from 'layouts/mainLayout'
import { getUser } from 'store/selectors'
import { loginUser } from 'store/actions'
import styled from 'styled-components'
import { useRouter } from 'next/router'
import { useState } from 'react'
import useTranslation from 'next-translate/useTranslation'

const Form = styled.form`
  background-color: ${(props) => props.theme.colors.primaryBackground};
  height: 100vh;
  max-height: 100vh;
  min-height: 450px;
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
`
const Box = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 10px;
`

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 0px;
`

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
`
const Logo = styled(LogoSVG)`
  max-width: 200px;
  width: 55%;
  min-width: 150px;
`
const ByBrit = styled(ByBritSVG)`
  max-width: 100px;
  width: 22%;
  min-width: 50px;
`

const Login = () => {
  const { t } = useTranslation('common')
  const router = useRouter()
  const dispatch = useDispatch()
  const [email, changeEmail] = useState({ value: '', error: '' })
  const [password, changePassword] = useState({ value: '', error: '' })

  const loggedUser = useSelector(getUser)
  if (loggedUser) {
    router.push('/')
    return null
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if (email.value === '') {
      changeEmail((prevState) => ({
        value: prevState.value,
        error: t('validation.email.REQUIRED'),
      }))
      return
    }

    if (password.value === '') {
      changePassword({ value: password.value, error: t('validation.password.REQUIRED') })
      return
    }

    dispatch(loginUser({ email: email.value, password: password.value }))
  }

  return (
    <MainLayout withHeader={false}>
      <LanguageSwitcher href="/login" />
      <Form onSubmit={(e) => handleSubmit(e)}>
        <Box>
          <LogoWrapper>
            <Logo />
            <ByBrit />
          </LogoWrapper>

          <Input
            id="email"
            onChange={(e) => changeEmail({ ...email, value: e.target.value })}
            placeholder={t('fields.EMAIL')}
            required={true}
            value={email.value}
            error={email.error}
            type="email"
          />
          <Input
            onChange={(e) => changePassword({ ...password, value: e.target.value })}
            placeholder={t('fields.PASSWORD')}
            required={true}
            value={password.value}
            error={password.error}
            type="password"
          />

          <Buttons>
            <Button type="submit" buttonType="primary" margin="15px 0 15px 0">
              {t('LOG_IN')}
            </Button>
            <Button href="/password-reset" buttonType="transparent">
              {t('FORGET_PASSWORD')}
            </Button>
            <Button href="/register" buttonType="transparent">
              {t('REGISTER')}
            </Button>
          </Buttons>
        </Box>
      </Form>
    </MainLayout>
  )
}

export default Login
